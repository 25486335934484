/* You can add global styles to this file, and also import other style files */
ag-grid-angular,
.ag-grid-angular
  height: 100%
  width: 100%

.mat-raised-button, .mat-dialog-container, .mat-form-field-flex, .mat-tooltip, .mat-pseudo-checkbox, .mat-checkbox-frame, .mat-checkbox-background
  border-radius: 0px !important

.mat-tooltip
  font-size: 11px

@media screen and (max-width: 1400px)
  .mat-button, .mat-raised-button, .mat-icon-button, .mat-stroked-button, .mat-flat-button, .mat-fab, .mat-mini-fab
    font-size: 12px !important

.ag-body-viewport-wrapper.ag-layout-normal
  overflow: auto
  overflow-y: hidden

::-webkit-scrollbar
  -webkit-appearance: none
  width: 8px
  height: 8px

::-webkit-scrollbar-thumb
  border-radius: 4px
  background-color: rgba(0,0,0,.5)
  box-shadow: 0 0 1px rgba(255,255,255,.5)
