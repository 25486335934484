// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use "~@angular/material" as mat;
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import "~ag-grid-community/dist/styles/ag-grid.css";
@import "~ag-grid-community/src/styles/ag-theme-material/sass/ag-theme-material-mixin";
@import "~ag-grid-community/src/styles/ag-theme-alpine-dark/sass/ag-theme-alpine-dark";

$md-appointfix-green: (
  50: #e4f7e0,
  100: #baebb3,
  200: #8dde80,
  300: #5fd04d,
  400: #3cc626,
  500: #1abc00,
  600: #17b600,
  700: #13ad00,
  800: #0fa500,
  900: #089700,
  A100: #c3ffc2,
  A200: #90ff8f,
  A400: #5eff5c,
  A700: #45ff42,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-appointfix-blue: (
  50: #e0f1fc,
  100: #b3dcf8,
  200: #80c5f4,
  300: #4dadef,
  400: #269ceb,
  500: #008ae8,
  600: #0082e5,
  700: #0077e2,
  800: #006dde,
  900: #005ad8,
  A100: #ffffff,
  A200: #cddeff,
  A400: #9abdff,
  A700: #80adff,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-appointfix-red: (
  50: #fde9ee,
  100: #f9c8d4,
  200: #f6a3b8,
  300: #f27e9c,
  400: #ef6286,
  500: #ec4671,
  600: #ea3f69,
  700: #e7375e,
  800: #e42f54,
  900: #df2042,
  A100: #ffffff,
  A200: #ffe3e7,
  A400: #ffb0bc,
  A700: #ff96a7,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #000000,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #000000,
    A400: #000000,
    A700: #000000,
  ),
);

$md-appointfix-gray: (
  50: #eaeaeb,
  100: #cacacc,
  200: #a7a7ab,
  300: #838389,
  400: #69696f,
  500: #4e4e56,
  600: #47474f,
  700: #3d3d45,
  800: #35353c,
  900: #25252b,
  A100: #7e7ef9,
  A200: #4e4ef7,
  A400: #1313ff,
  A700: #0000f8,
  contrast: (
    50: #000000,
    100: #000000,
    200: #000000,
    300: #000000,
    400: #ffffff,
    500: #ffffff,
    600: #ffffff,
    700: #ffffff,
    800: #ffffff,
    900: #ffffff,
    A100: #000000,
    A200: #ffffff,
    A400: #ffffff,
    A700: #ffffff,
  ),
);

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
// $admin-area-web-primary: mat.define-palette(mat.$teal-palette);
$admin-area-web-primary: mat.define-palette($md-appointfix-blue);
$admin-area-web-accent: mat.define-palette(mat.$blue-gray-palette, A200, A100, A400);

// The warn palette is optional (defaults to red).
$admin-area-web-warn: mat.define-palette($md-appointfix-red);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$admin-area-web-theme: mat.define-light-theme(
  (
    color: (
      primary: $admin-area-web-primary,
      accent: $admin-area-web-accent,
      warn: $admin-area-web-warn,
    ),
  )
);

$admin-area-dark-web-theme: mat.define-dark-theme(
  (
    color: (
      primary: $admin-area-web-primary,
      accent: $admin-area-web-accent,
      warn: $admin-area-web-warn,
    ),
  )
);

.ag-theme-material {
  @include ag-theme-material(
    (
      material-primary-color: #008ae8,
      material-accent-color: #b0bec5,
      background-color: #fafafa,
      row-hover-color: #eceff1,
      header-column-separator: true,
      header-column-separator-height: 34%,
      header-cell-hover-background-color: none,
      header-background-color: #008ae8,
      header-foreground-color: #fafafa,
    )
  );
}
// #26A69A, #B0BEC5
// @include mat.all-component-themes($admin-area-web-theme);

.color-scheme-dark {
  @include mat.all-component-themes($admin-area-dark-web-theme);
  .ag-theme-material {
    @include ag-theme-alpine-dark(
      (
        alpine-active-color: #008ae8,
        range-selection-border-color: #008ae8,
        background-color: #303030,
        row-border-color: transparent,
      )
    );
  }
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
// @include mat.all-component-themes($admin-area-web-theme);
.color-scheme-light {
  @include mat.all-component-themes($admin-area-web-theme);
}

html,
body {
  height: 100%;
}
body {
  margin: 0;
  line-height: 1.6;
  // letter-spacing: 1.1px;
  color: #4e4e56;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}
html {
  overflow: auto;
}
// ::-webkit-scrollbar {
//   width: 0px;
//   background: transparent; /* make scrollbar transparent */
// }
